h1 {
  margin: 25px 0;
}

#page {
  width: 100vw;
  height: 100vh;
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #051e32;
}

#nav {
  width: 20vw;
  height: 100vh;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

#navProfile {
  height: 100px;
  aspect-ratio: 1;
  border-radius: 50%;
  align-self: center;
  -webkit-touch-callout: none;
}

#navHeading {
  margin: 10px 0 5px;
}

#nav h5 {
  margin: 0;
  font-style: italic;
}

.navLinksMobile {
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 20px 0 0 20px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  transition: all 0.3s;
}

.navLinksMobile_active {
  width: 100%;
  background-color: #051e32e8;
}

.navLinksMobile button {
  color: white;
  background-color: #051e32;
  height: 50px;
  width: 50px;
  border: solid white 1px;
  border-radius: 10%;
}

.currentPage {
  font-style: italic;
}

.disabledLink {
  text-decoration: line-through #051e32;
}

#nav a {
  color: white;
  text-decoration-color: transparent;
}

#nav a:hover {
  text-decoration-color: white;
  text-underline-offset: 10px;
}

#content {
  width: 80vw;
  height: 100vh;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

@media (max-width: 768px) {
  #page {
    flex-direction: column;
    height: fit-content;
    min-height: 100vh;
  }

  #nav {
    width: 100vw;
    height: fit-content;
    margin-top: 50px;
    padding: 0;
  }

  #content {
    width: 100vw;
    height: fit-content;
    margin-top: 25px;
  }
}
