.card {
  width: 100%;
  margin-bottom: 25px;
  padding: 25px;
  background-color: white;
  color: black;
  border-radius: 5px;
  box-shadow: 0 0 12px 2px black;
  transition: all 0.3s;
}

.card:active {
  box-shadow: 0 0 8px 2px black;
  transform: scale(0.98) translateY(2px);
}

@media (hover: hover) {
  .card:hover {
    box-shadow: 0 0 8px 2px black;
    transform: scale(0.98) translateY(2px);
  }
}
