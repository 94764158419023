#home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: auto;
}

#home a,
#home a:link,
#home a:active,
#home a:visited {
  color: black;
  text-decoration-color: black;
  text-underline-offset: 2px;
}
